@import "./variables.scss";

@import url("http://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;500;700&display=swap");

html {
	width: 100%;
	//height: 100%;
	background-color: $background-color;
	vertical-align: top;
	position: relative;
}

body {
	width: 100%;
	//height: 100%;
	-webkit-tap-highlight-color: rgba(black, 0);
	font-size: 16px;
	background-color: $background-color;
	position: relative;
}

* {
	vertical-align: inherit;
}

#root {
	position: relative;
	min-height: 100vh;
	//overflow-x: hidden;
}

.recharts-cartesian-grid-vertical {
	line {
		stroke-dasharray: none;
	}
}

.date-picker-select .MuiSelect-selectMenu {
	padding-top: 15px;
	padding-bottom: 15px;
}

.rdrDayToday .rdrDayNumber span:after {
	background: $main-color !important;
}

.MuiSelect-outlined {
		padding: 14px 16px !important;

}

.notistack-SnackbarContainer {
	z-index: 15000;
}
