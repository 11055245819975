@import "root/css/variables.scss";

.root {
	border-bottom: solid 1px $border-color;
}
.indicator {
	background-color: $main-color;
	height: 3px;
}
.disabled {
	opacity: 0.4 !important;
}
