@import "root/css/variables.scss";

.root {
	position: relative;
	background-color: rgba(white, 0.1);
	width: 100%;
	height: calc(100vh - #{$header-height} - 60px);
	display: flex;
	align-items: center;
	justify-content: center;
	@media screen and (max-height: 500px) {
		align-items: flex-start;
	}

	.box {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 92px 80px;
		background-color: white;
		max-width: 70%;
		border-radius: 3px;
		overflow: hidden;
		@media screen and (max-width: $sm) {
			width: 100%;
			max-width: 100%;
			padding: 30px 20px;
		}

		.title {
			width: 100%;
			text-align: center;
			font-weight: 500;
			font-size: 24px;
			color: $gun-powder-color;
			margin-bottom: 20px;
			line-height: 1.1em;
			@media screen and (max-width: $sm) {
				font-size: 18px;
			}
		}

		.subtitle {
			width: 100%;
			text-align: center;
			font-size: 16px;
			color: #798097;
			margin-bottom: 25px;
			@media screen and (max-width: $sm) {
				font-size: 14px;
			}
		}
	}
}
