@import "root/css/variables.scss";

.root {
	position: fixed;
	overflow: auto;
	top: 0;
	left: 0;
	height: 100vh;
	width: calc(100% - #{$drawer-width});
	z-index: 1299;
	background-color: rgb(240, 242, 245);
	left: $drawer-width;
	transition: $transition;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 136px;
	&[data-drawer-open="false"] {
		left: $drawer-minimized-width;
		width: calc(100% - #{$drawer-minimized-width});
		@media screen and (max-width: $md) {
			left: 0;
			width: 100%;
		}
	}
	&[data-fullwidth="true"] {
		top: 0;
		bottom: 0;
		left: 0.5px;
		right: 0;
		width: 100%;
		height: 100%;
	}
	@media screen and (max-width: $md) {
		left: 0;
		width: 100%;
		top: $header-height;
		height: calc(100% - #{$header-height});
		flex-direction: column;
		justify-content: flex-start;
		padding: 20px 100px 100px 100px;
	}
	@media screen and (max-width: 720px) {
		padding: 20px 20px 100px 20px;
	}

	.container {
		position: absolute;
		top: 0;
		padding: 100px 70px;

		@media screen and (max-width: $sm) {
			padding: 50px 35px;
		}
	}

	.left {
		position: relative;
		padding-right: 40px;
		@media screen and (max-width: $md) {
			padding-right: 0;
		}
	}

	.subject {
		color: #262626;
		font-size: 34px;
		line-height: 40px;
		margin: 0;
		margin-bottom: 20px;
		font-weight: 500;
	}

	.description {
		font-size: 16px;
		line-height: 24px;
		color: #798097;
		font-weight: 400;
		margin: 0;
		margin-bottom: 20px;
	}

	.description2 {
		display: flex;
		flex-direction: column;
		background: white;
		border-radius: 8px;
		padding: 24px;
		line-height: 24px;

		.message {
			position: relative;
			width: 100%;
			color: white;
			border-radius: 8px;
			margin-bottom: 15px;
			color: $faded-color;
		}

		.staff {
			display: flex;

			img {
				flex: none;
				width: 50px;
				height: 50px;
				margin-right: 16px;
				border-radius: 100%;
				image-rendering: -webkit-optimize-contrast;
			}

			.info {
				display: flex;
				flex-direction: column;
				justify-content: center;

				.name {
					color: #262626;
					font-weight: 500;
					font-size: 14px;
					margin-bottom: 2px;
				}

				.job {
					font-size: 14px;
					color: #798097;
				}
			}
		}
	}

	.tg {
		font-size: 14px;
		color: #798097;
		margin-top: 40px;
		&[data-absolute] {
			position: absolute;
			margin-top: 0;
			bottom: -40px;
		}

		a {
			color: $main-color;
		}
	}

	.anyQuestions {
		padding: 40px;

		img {
			width: 100%;
			border-radius: 10px;
			image-rendering: -webkit-optimize-contrast;
		}
	}
}
