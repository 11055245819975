@import "root/css/variables.scss";
// @import "../../css/variables.scss";

$section-icon-size: 18px;

.item {
	position: relative;
	width: 100%;
	height: 40px;
	padding: 0 10px 0 28px;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	transition: $transition;
	&[data-active="true"] {
		background-color: #313a59;
		.dot {
			background-color: white;
		}
		.label {
			color: white;
		}
	}
	&:hover {
		background-color: #313a59;
		.dot {
			box-shadow: 0 0 0 1px white;
		}
		.label {
			color: white;
		}
	}

	.dot {
		position: relative;
		flex: none;
		width: 4px;
		height: 4px;
		transition: $transition;
		box-shadow: 0 0 0 1px #8897b9;
		margin-right: 15px;
		border-radius: 50%;
	}

	.label {
		position: relative;
		color: #8897b9;
		font-size: 14px;
		transition: $transition;
		white-space: nowrap;
		line-height: 1em;
		flex-grow: 1;
	}

	.lock {
		width: 16px;
		height: 16px;
		flex: none;
		color: rgba(207, 58, 160, 0.6);
		transition: $transition;
	}
}

.section {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	user-select: none;
	overflow: hidden;
	&[data-open="false"] {
		.title {
			padding: 0 (($drawer-minimized-width * 0.5) - ($section-icon-size * 0.5));

			.label,
			.lock,
			.expandIcon {
				opacity: 0;
			}
		}
	}
	&[data-active="true"] {
		background-color: #131932;
	}

	.activeIndicator {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 3px;
		background-color: #eb255d;
		//transition: $transition;
		flex: none;
		z-index: 1;
	}

	.title {
		position: relative;
		width: 100%;
		height: 40px;
		padding: 0 10px 0 20px;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		transition: $transition;
		font-weight: 500;
		color: #fff;
		text-transform: uppercase;
		&[data-active="true"] {
			background-color: #313a59;

			.label {
				color: #fff;
			}

			.icon {
				color: white;
			}
		}
		&:hover {
			background-color: #313a59;
		}

		.icon {
			position: relative;
			width: $section-icon-size;
			height: $section-icon-size;
			flex: none;
		}

		.label {
			position: relative;
			flex-grow: 1;
			font-size: 12px;
			font-weight: 500;
			//transition: $transition;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			// line-height: 1em;
			margin-left: 10px;
			transition: $transition;
		}

		.lock {
			width: 16px;
			height: 16px;
			flex: none;
			color: rgba(207, 58, 160, 0.6);
			transition: $transition;
		}

		.expandIcon {
			position: relative;
			color: #a2a6b4;
			width: 18px;
			height: 18px;
			transition: $transition;
			flex: none;
			&[data-expanded="true"] {
				transform: rotate(180deg);
			}
		}
	}

	.divider {
		background-color: #313a59;
		z-index: 2;
	}
}
