@import "root/css/variables.scss";

.body {
	background-image: url("../../img/background.png");
	background-position: center;
	background-size: cover;
	background-color: $background-color;
}

.header {
	position: relative;
	z-index: 9;
	width: 100%;
	margin-top: 50px;
	margin-bottom: 120px;
	display: flex;
	justify-content: flex-start;
	@media screen and (max-width: $sm) {
		margin-bottom: 80px;
		margin-top: 30px;
		justify-content: center;
	}

	img {
		position: relative;
		height: 17px;
		flex: none;
	}
}

.title {
	position: relative;
	font-size: 42px;
	display: inline-block;
	color: $secondary-color;
	margin-bottom: 32px;
	line-height: 1.2em;
	@media screen and (max-width: $sm) {
		font-size: 30px;
	}

	.badge {
		position: absolute;
		left: calc(100% + 4px);
		top: -18px;
		font-weight: 600;
		font-size: 20px;
		color: white;
		padding: 4px 12px;
		border-radius: 10px 10px 10px 0;
		background-color: $main-color;
		line-height: 20px;
		@media screen and (max-width: $sm) {
			font-size: 16px;
			padding: 2px 10px;
			display: inline-block;
			right: 0;
			left: unset;
			top: -20px;
			position: absolute;
		}
	}
}

.description {
	font-size: 14px;
	color: $gun-powder-color;
	font-size: 16px;
}
