@import "root/css/variables.scss";

.root {
	padding: 0;
	margin-right: 30px;
	padding: 0;
	text-transform: none;
	min-width: unset;
	max-width: unset;
	height: 44px;
	color: $faded-color;
	font-size: 16px;
	font-weight: 400;
}
.textColorInherit {
	//color: $faded-color;
	//font-size: 16px;
	//font-weight: 400;
}
.selected {
	color: $main-color;
	font-weight: 500;
}
