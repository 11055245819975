$font-family: "Roboto", "Helvetica", "Arial", sans-serif;
$transition: all 0.3s cubic-bezier(0.25, 0.8, 0.05, 1);

$sign-background-color: #0b1024;
$box-shadow-color: #0000001f;
$eerie-black-color: #262626;
$secondary-color: #212843;
$gun-powder-color: #43455c;
$secondary-text-color: #7c8298;
$breadCrumb-text-color: #6a6d81;
$tooltip-color: #6c748c;
$faded-color: #798097;
$warning-color: #798097;
$circular-progress-color: #b5bac7;
$loading-box-progress-color: #c8c8c8;
$background-color: #f0f2f5;
$background-color-total: #fafafa;
$breadCrumb-border-color: #e0e4ee;
$breadCrumb-empty-link-color: #aeb2c0;
$breadCrumb-empty-link-border: #d4d4d4;
$breadCrumb-empty-link-background: #e0e4ef;
$table-row-background-color: #fafafb;
$secondary-background-color: #f2f3f7;
$data-transparent-border: #dcdfea;
$scrollbar-thumb-background-color: #d8d8d8;
$pagination-border-shadow-color: #e0e0e0;
$border-color: #d6dae0;
$data-zero-color: #1e85ff;
$outline-color: #82b9ee;
$success-color: #0fb682;
$percent-color: #22c829;
$helper-text-color: #dd2323;
$error-color: #f00;
$error-border-color: #f44336;
$data-negative: #e0274f;
$main-color: #cf3aa0;
$background-hover-color: #e12da9;
$password-dot-color: #ff0000;
$drawer-background-color: #131932;
$drawer-divider-color: #313a59;
$drawer-color: #8897b9;
$drawer-icon-color: #a2a6b4;
$drawer-indicator-background-color: #eb255d;
$weak-password-gradient-background: linear-gradient(90deg, #e72e2e 0%, #b91339 100%);
$moderate-password-gradient-background: linear-gradient(90deg, #e7872e 0%, #c8e31f 100%);
$strog-password-gradient-background: linear-gradient(90deg, #4ce72e 0%, #28c11e 100%);
$done-icon-color: #37bb54;

$header-height: 64px;
$drawer-width: 240px;
$drawer-minimized-width: 72px;

$xl: 1920px - 1px;
$lg: 1280px - 1px;
$md: 960px - 1px;
$sm: 600px - 1px;