@import "root/css/variables.scss";

.root {
	font-size: inherit;
	color: $main-color;
	text-decoration: none;
	cursor: pointer;

	&[data-underline="always"] {
		text-decoration: underline;
	}
	&[data-underline="hover"] {
		&:hover {
			text-decoration: underline;
		}
	}
}
