@import "root/css/variables.scss";

.root {
	position: relative;
}

.labelRoot {
	font-weight: 400;
	font-size: 16px;
	color: $faded-color;
	transform: translate(14px, 17px) scale(1);
}

.labelFocused {
	color: $outline-color !important;
}

.labelShrink {
	transform: translate(14px, -6px) scale(0.87) !important;
}

.InputRoot {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	padding-left: 0 !important;

	&:hover .notchedOutline {
		border-color: $border-color;
	}

	.notchedOutline {
		border-color: $border-color;
	}

	&.error {
		.notchedOutline {
			border-color: $error-border-color !important;
		}
	}

	&.focused {
		.notchedOutline {
			border-color: $outline-color;
		}
	}
}

.inputRoot {
	font-size: 16px;
	padding: 14px 16px 13px 16px;
	background-color: #fff;

	&::placeholder {
		color: $faded-color;
		opacity: 1;
	}
}

.helperTextRoot {
	color: $faded-color;
	margin-left: 0;
	margin-right: 0;
	font-size: 16px;

	&.error {
		color: $error-border-color;
	}
}
