@import "root/css/variables.scss";

$open-button-size: 28px;

.paper {
	width: $drawer-width;
	background-color: $secondary-color;
	display: flex;
	flex-direction: column;
	overflow: auto;
	border-right: 0;
	&[data-transition="true"] {
		transition: $transition;
    z-index: 1302;
	}
	&[data-open="false"] {
		width: $drawer-minimized-width;
    z-index: 1302;

		.header {
			.logoWithText {
				opacity: 0;
			}
		}

		.tgWrapper {
			margin: 10px 0;

			.tg {
				border-radius: 99px;
				width: 40px;
				height: 40px;
				padding: 0;

				.tgRocketLogo {
					opacity: 0;
				}

				.tgLogo {
					opacity: 1;
				}

				.tgInfo {
					opacity: 0;
				}
			}
		}
	}

	.wrapper {
		width: 100%;

		.header {
			position: relative;
			width: 100%;
			height: $header-height;
			flex: none;
			overflow: hidden;

			.logoWrapper {
				position: relative;
				display: block;
				height: 18px;
				left: 20px;
				top: calc(#{$header-height} / 2 - 8.5px);
				z-index: 1;

				.logoWithText {
					position: absolute;
					flex: none;
					height: 100%;
					transition: $transition;
				}

				.logo {
					position: absolute;
					height: 100%;
				}
			}

			.openButton {
				position: absolute;
				right: 10px;
				top: calc((#{$header-height} / 2) - (#{$open-button-size} / 2));
				height: $open-button-size;
				width: $open-button-size;
				color: white;
				transition: $transition;
				flex: none;
				transform: rotate(180deg);
				cursor: pointer;
				z-index: 2;
				&[data-open="true"] {
					transform: rotate(0deg);
				}
			}
		}

		.sections {
			position: relative;
			width: 100%;
			cursor: pointer;
		}
	}

	.tgWrapper {
		margin: 20px 0;
		color: unset;
		text-decoration: none;
		display: flex;
		justify-content: center;

		.tg {
			position: relative;
			background-color: rgba(0, 136, 204, 1);
			border-radius: 10px;
			height: 52px;
			width: calc(100% - 20px);
			display: flex;
			align-items: center;
			padding: 8px;
			overflow: hidden;
			transition: $transition;

			.tgRocketLogo {
				width: 36px;
				height: 36px;
				flex: none;
				margin-right: 12px;
				border-radius: 50%;
				transition: $transition;
			}

			.tgLogo {
				position: absolute;
				width: 20px;
				height: 20px;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				color: white;
				opacity: 0;
				transition: $transition;
			}

			.tgInfo {
				color: white;
				transition: $transition;

				.tgTitle {
					font-weight: 500;
					font-size: 12px;
					margin-bottom: 2px;
					white-space: nowrap;
				}

				.tgSubtitle {
					font-size: 11px;
					white-space: nowrap;
				}
			}
		}
	}
}

.deteils {
	position: fixed;
}

// .deteils::-webkit-scrollbar {
//   width: 7px;
//   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
//   border-radius: 10px;
//   background-color: #f9f9fd;
// }
.paper::-webkit-scrollbar {
	width: 4px;
}

.deteils::-webkit-scrollbar {
	width: 4px;
}

.deteils::-webkit-scrollbar-track {
	-webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
	background-color: #f9f9fd;
	border-radius: 10px;
}

.deteils::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: linear-gradient(180deg, #d73b9e, #f5265f);
}
