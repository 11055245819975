@import "root/css/variables.scss";

.root {
	position: relative;
	width: 100%;
	height: calc(100vh - #{$header-height});

	.progress {
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		top: 0;
		margin: auto;
		color: lightgray;
	}
}
