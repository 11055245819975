@import "root/css/variables.scss";

.paper {
	width: 596px;
	max-width: 100%;

	.closeIconWrapper {
		$wrapper-size: 24px;
		$icon-size: 22px;
		position: absolute;
		top: 22px;
		right: 22px;
		width: $wrapper-size;
		height: $wrapper-size;
		padding: ($wrapper-size - $icon-size) * 0.5;
		z-index: 2;
		outline: none;

		.closeIcon {
			position: relative;
			width: $icon-size;
			height: $icon-size;
			color: $faded-color;
			cursor: pointer;
		}
	}

	.root {
		position: relative;
		width: 100%;
		height: 100%;
		padding: 0 25px 25px 25px;
		@media screen and (max-width: $sm) {
			padding: 0 10px 10px 10px;
		}

		.header {
			position: relative;
			width: calc(100% - 28px);
			min-height: 64px;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			padding: 10px 0;

			.title {
				font-size: 18px;
				font-weight: 500;
				color: $gun-powder-color;
				margin-right: 10px;
			}

			.readAll {
				font-size: 14px;
				text-decoration: underline;
				cursor: pointer;
				line-height: 1.1em;
				color: $main-color;
			}
		}

		.notifications {
			position: relative;
			display: flex;
			flex-direction: column;

			.notification {
				position: relative;
				display: flex;
				flex-direction: column;
				padding: 20px;
				&[data-is-read="false"] {
					background-color: #fff8fd;
				}
				&[data-is-read="true"] {
					.text {
						color: $faded-color;
					}
				}
				@media screen and (max-width: $sm) {
					padding: 12px;
				}

				.date {
					width: 100%;
					text-transform: uppercase;
					color: $faded-color;
					font-size: 12px;
					margin-bottom: 2px;
				}

				.text {
					width: 100%;
					color: $eerie-black-color;
					font-size: 16px;
					line-height: 1.3em;
				}

				.button {
					align-self: flex-start;
					font-size: 16px;
				}
			}
		}
	}
}
