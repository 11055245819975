@import "root/css/variables.scss";

.root {
	padding: 0;
	font-weight: 400;
	font-size: 14px;
	font-family: inherit;
	display: flex;
	align-items: center;
	user-select: none;
	outline: none;
	transition: $transition;
	text-transform: none;
	text-align: left;
	line-height: 1.2em;
	&[data-bigger="true"] {
		font-size: 16px;
	}
	&[data-inline="true"] {
		display: inline;
	}
	&[data-whitespace="nowrap"] {
		white-space: nowrap;
	}
	&[data-whitespace="normal"] {
		white-space: normal;
	}

	&:hover {
		background-color: transparent;
	}

	&[disabled] {
		opacity: 0.6;
		pointer-events: none;
	}

	&[data-fullwidth] {
		width: 100%;
	}

	&[data-variant="primary"] {
		color: $main-color;
	}
	&[data-variant="primary"]:not([disabled]):hover {
		color: $background-hover-color;
	}
	&[data-variant="primary"]:not([disabled]):active {
		color: $main-color;
	}

	&[data-variant="faded"] {
		color: $faded-color;
	}

	@mixin icon {
		position: relative;
		font-size: inherit;
		width: 1.2em;
		height: 1.2em;
		flex: none;
		color: inherit;
	}
	.iconBefore {
		@include icon;
		margin-right: 6px;
	}
	.iconAfter {
		@include icon;
		margin-left: 6px;
	}

	.label {
		color: inherit;

		&[data-underlined="true"] {
			text-decoration: underline;
		}
	}
}
