@import "root/css/variables.scss";

.root {
	position: relative;
	display: flex;
	flex-direction: column;
	background-color: white;
	padding: 20px 25px;
	box-shadow: -1px 7px 15px rgba(black, 0.07);
	justify-content: flex-start;
	align-items: flex-start;
	max-width: 260px;
	width: 100%;
	overflow: hidden;
	border-radius: 0;
	@media screen and (max-width: $sm) {
		max-width: unset;
	}

	.closeIcon {
		position: absolute;
		right: 8px;
		top: 8px;
		color: $faded-color;
		width: 16px;
		height: 16px;
		cursor: pointer;
	}

	.title {
		font-size: 14px;
		color: $gun-powder-color;
		font-weight: 500;
		margin-bottom: 10px;
	}

	.message {
		font-size: 14px;
		color: $tooltip-color;
	}

	.indicator {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 5px;

		&[data-type="success"] {
			background-color: $success-color;
		}
		&[data-type="error"] {
			background-color: $password-dot-color;
		}
		&[data-type="default"] {
			background-color: $main-color;
		}
		&[data-type="warning"] {
			background-color: #fcbc00;
		}
	}
}
