@use "sass:math";

@import "root/css/variables.scss";

.desktop {
	position: fixed;
	height: $header-height;
	background-color: white;
	top: 0;
	right: 0;
	left: $drawer-width;
	transition: $transition;
	z-index: 12;
	display: flex;
	align-items: center;
	padding: 0 10px;

	&[data-is-drawer-open="false"] {
		left: $drawer-minimized-width;
	}

	.iconWrapper {
		border-right: 1px solid $border-color;
		display: flex;
		align-items: center;
		height: 100%;
	}

	.grow {
		flex-grow: 1;
	}

	.badgeWrapper {
		padding: 0 26px;
		height: 100%;
		border-left: 1px solid $border-color;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.badge {
		border: 2px solid white;
	}

	.iconButton {
		position: relative;
		color: $gun-powder-color;
		width: math.div($drawer-minimized-width, 1.7);
		height: math.div($drawer-minimized-width, 1.7);
	}

	.drawerIcon {
		transition: $transition;

		&[data-isdraweropen="false"] {
			transform: rotate(180deg);
		}
	}

	.userWrapper {
		position: relative;
		padding: 0 18px;
		height: $header-height;
		flex: none;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		color: $gun-powder-color;
		border-left: 1px solid $border-color;
		border-right: 1px solid $border-color;

		.icon {
			margin-right: 8px;
		}
	}
}

.mobile {
	position: fixed;
	height: $header-height;
	width: 100%;
	background-color: white;
	top: 0;
	right: 0;
	z-index: 12;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 10px;

	.iconButton {
		position: relative;
		color: $gun-powder-color;
		width: math.div($drawer-minimized-width, 1.7);
		height: math.div($drawer-minimized-width, 1.7);
	}
}

.exitWrapper {
	padding-right: 16px;
	padding-left: 26px;
}
